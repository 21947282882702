import { apiUrl } from '../constant';

const createSession = async (data: string): Promise<string> => {
    console.log('create session length', data.length);
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${apiUrl()}/auth-sessions`, true);
        xhr.timeout = 30000;
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            const response = JSON.parse(this.responseText);
            if (response.error_code) {
                reject(response.message);
            } else {
                resolve(response.key);
            }
        };
        xhr.onerror = function () {
            reject(this.statusText);
        };
        xhr.send(`data=${data}`);
    });
};

export default createSession;
