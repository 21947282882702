import { getParticleEnv } from './utils';

const authUrl = (): string => {
    const productionAuthUrl = 'https://auth.particle.network';
    const stagingAuthUrl = 'https://auth-staging.particle.network';
    const developmentAuthUrl = 'https://auth-debug.particle.network';

    const env = getParticleEnv();

    if (
        typeof window !== 'undefined' &&
        window.__PARTICLE_AUTH_LOCALHOST__ &&
        typeof window.__PARTICLE_AUTH_LOCALHOST__ === 'string' &&
        window.__PARTICLE_AUTH_LOCALHOST__.includes('localhost')
    ) {
        return window.__PARTICLE_AUTH_LOCALHOST__;
    }
    switch (env) {
        case 'production':
            return productionAuthUrl;
        case 'staging':
            return stagingAuthUrl;
        case 'development':
            return developmentAuthUrl;
    }
};

const apiUrl = (): string => {
    const productionApi = 'https://api.particle.network';
    const developmentApi = 'https://api-debug.particle.network';
    const env = getParticleEnv();
    return env === 'development' ? developmentApi : productionApi;
};

const rpcUrl = (): string => {
    const productionApi = 'https://rpc.particle.network';
    const developmentApi = 'https://rpc-debug.particle.network';
    const env = getParticleEnv();
    return env === 'development' ? developmentApi : productionApi;
};

const walletUrl = (): string => {
    const productionUrl = 'https://wallet.particle.network';
    const stagingUrl = 'https://wallet-staging.particle.network';
    const developmentUrl = 'https://wallet-debug.particle.network';
    const env = getParticleEnv();
    switch (env) {
        case 'production':
            return productionUrl;
        case 'staging':
            return stagingUrl;
        case 'development':
            return developmentUrl;
    }
};

const buyUrl = (): string => {
    const productionUrl = 'https://ramp.particle.network';
    const stagingUrl = 'https://ramp-staging.particle.network';
    const developmentUrl = 'https://ramp-debug.particle.network';
    const env = getParticleEnv();
    switch (env) {
        case 'production':
            return productionUrl;
        case 'staging':
            return stagingUrl;
        case 'development':
            return developmentUrl;
    }
};

export { apiUrl, authUrl, buyUrl, rpcUrl, walletUrl };
